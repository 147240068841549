import React from 'react';

import Hero from "./Hero";
import Intro from "./Intro";
import Features from "./Features";
import Footer from "./Footer";
import DownloadButton from '../../common/DownloadButton';

function Home() {
    return (
        <div style={{paddingTop: 40}}>
            <Hero/>
            <Intro/>
            {/*<Features/>*/}
            {/*<Footer/>*/}


            <div style={{display: 'flex', justifyContent: 'center', padding: 50}}>
            <DownloadButton/>
            </div>
        </div>
    );
}

export default Home;
