import React from 'react';

import {Col, Row, Image, Container} from "react-bootstrap";
import EmailForm from "../../common/EmailForm";
import DownloadButton from '../../common/DownloadButton';

function Hero() {
    return (
        <Container style={{marginBottom: 80, padding: 20}}>
            <Row className={'justify-content-center'}>
                <Col sm={12} md={5} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 40}}>
                    <Image width={120} src={"/images/icon.png"}/>
                    <h1>DocChat</h1>
                    <p style={{marginTop: 20, marginBottom: 40}}>
                        With DocChat, you can get quick and relevant answers to your questions without the need to spend time reading through lengthy documents or searching for information.
                    </p>
                    <p>
                        Our natural language processing technology makes it easy and intuitive to find the information you need, allowing you to save time and effort. Say goodbye to tedious research and hello to a more efficient and user-friendly experience with our app!
                    </p>

                    <DownloadButton/>
                </Col>

                <Col sm={12} md={7} lg={4} style={{display: 'flex', justifyContent: 'center'}}>
                    <img style={{width: '100%', height: 'auto'}} src={"/images/mocks/2.png"}/>
                </Col>
            </Row>
        </Container>
    );
}

export default Hero;
