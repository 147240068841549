import React from 'react';

import {Col, Row, Image, InputGroup, Form, Card, Container} from "react-bootstrap";

function Intro() {
    return (
        <div className="glass-container" style={{padding: 40}}>
            <Row className="justify-content-center" >
                <Col xs={12} sm={10} md={12} lg={8} style={{textAlign: 'center'}}>
                    <Image src={"/images/apple.png"} width={60} />

                    <p style={{marginTop: 20}}>
                        Available on the App Store
                    </p>
                    <h4 style={{marginBottom: 20}}>
                        Get instant answers to your questions with our intuitive app powered by natural language processing.
                    </h4>

                    <p>
                        Allowing a user to ask questions to an app can save time and effort by providing quick and relevant answers to their queries. This can eliminate the need to search through large amounts of information or read through lengthy documents, making it easier for users to find the information they need.
                    </p>
                    <p>
                        Additionally, an app that can answer questions in natural language can provide a more intuitive and user-friendly experience, reducing the learning curve and improving overall usability.
                    </p>
                </Col>

                {/*<Col sm={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>*/}
                {/*    <Image height={600} src={"/images/mocks/2.png"}/>*/}
                {/*</Col>*/}
            </Row>
        </div>
    );
}

export default Intro;
