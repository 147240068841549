import React from 'react';

import Button from 'react-bootstrap/Button';
import {InputGroup, Form} from "react-bootstrap";
import { collection, addDoc, getFirestore } from "firebase/firestore"; 
import iosButton from './ios.svg';
import macButton from './mac.svg';

const ios = 'https://apps.apple.com/us/app/doc-chat-ai/id6449497040';
const mac = 'https://apps.apple.com/us/app/doc-chat-ai/id6449497040';

function DownloadButton() {
    return (
        <div style={{display: 'flex'}}>
            <a href={ios} target="_blank" style={{marginRight: 10}}>
            <img src={iosButton} />
            </a>
            <a href={mac} target="_blank" style={{marginLeft: 10}}>
            <img src={macButton} />
            </a>
        </div>
    );
}

export default DownloadButton;
