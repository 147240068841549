
/**************START FIREBASE **************/
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyCmY-jDGWjO8dEgtDx8p1d2qrtWW2ChAoQ",
    authDomain: "docchatai.firebaseapp.com",
    projectId: "docchatai",
    storageBucket: "docchatai.appspot.com",
    messagingSenderId: "835135009898",
    appId: "1:835135009898:web:af73c40a36aff144aa4613",
    measurementId: "G-MSD7EQ6Q8K"
};

/**************END FIREBASE **************/

let app: FirebaseApp | null = null;
let db: any = null;
const firebaseApp = (): FirebaseApp => {
    if (app) return app;

    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    const analytics = getAnalytics(app);

    return app;

}

const firebaseDB = (): Firestore => {
    if (db) return db;

    db = getFirestore(firebaseApp());
    return db;
}

export { firebaseApp, firebaseDB };
