import React, {useEffect, useState} from 'react';
import './styles/_root.scss';
import Home from "./screens/home/Home";

function App() {
    return (
        <div>
            <Home/>
        </div>
    );
}

export default App;
